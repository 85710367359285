import React from "react";
import PropTypes from "prop-types";
import { IconButton, Button, ButtonToolbar, Popover, Carousel, FlexboxGrid } from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import CloseIcon from "@rsuite/icons/Close";

class SessionPopover extends React.Component {
    static get propTypes() { 
        return { 
            session: PropTypes.object.isRequired,
            popoverStyle: PropTypes.object.isRequired,
            popoverClassName: PropTypes.string.isRequired,
            popoverRef: PropTypes.func.isRequired,
            closePopover: PropTypes.func.isRequired,
            onViewLevel: PropTypes.func,
            onViewLevelSessions: PropTypes.func,
            onViewUserSessions: PropTypes.func
        }; 
    }

    constructor(props) {
        super(props);

        this.state = {
            screenshotUrls: []
        };
    }

    componentDidMount() {
        this.loadNextScreenshot(2);
    }

    loadNextScreenshot(index) {
        let url = `/static/levels/Level${this.props.session.level}-${index}.jpg`;

        let http = new XMLHttpRequest();
        http.onload = () => {
            if (http.status == 200) {
                if (http.getResponseHeader("content-type") == "image/jpeg") {
                    this.setState((state) => ({
                        screenshotUrls: state.screenshotUrls.concat([url])
                    }));
    
                    if (index < 6) {
                        this.loadNextScreenshot(index + 1);
                    }
                }
            }
        }

        http.open('HEAD', url, true);
        http.send();
    }

    render() {
        const valueStyle = {
            fontWeight: "bold"
        };

        return (
            <Popover style={this.props.popoverStyle} className={this.props.popoverClassName} ref={this.props.popoverRef}>
                <div style={{maxWidth: "1000px"}}>
                    <div style={{ position: "absolute", top: 0, right: 0, paddingRight: 10, paddingTop: 10}}>
                        <IconButton icon={<CloseIcon/>} onClick={this.props.closePopover}></IconButton>
                    </div>

                    <FlexboxGrid>
                        <FlexboxGridItem>
                            <Carousel placement="bottom" shape="dot" style={{width: "400px", height: "300px"}}>
                                <img src={`/static/levels/Level${this.props.session.level}.jpg`}/>
                                {this.state.screenshotUrls.map((value) => 
                                    <img key={value} src={value}/>
                                )}
                            </Carousel>
                        </FlexboxGridItem>
                        <FlexboxGridItem>
                            <div style={{paddingLeft: 10}}>
                                <h5>Уровень: {this.props.session.level}</h5>
                                <p style={{paddingTop: 10}}>
                                    Дата: <span style={valueStyle}>{this.props.session.finishTimestampView}</span><br/>
                                    Результат: <span style={valueStyle}>{this.props.session.winView}</span><br/>
                                    Длительность: <span style={valueStyle}>{this.props.session.durationView}</span><br/>
                                    Осталось ходов (или секунд): <span style={valueStyle}>{this.props.session.remainingMoves}</span><br/>
                                    Докуплено ходов (или секунд): <span style={valueStyle}>{this.props.session.additionalMoves}</span><br/>
                                    Использованы powerup-ы: <span style={valueStyle}>{this.props.session.powerupsView}</span><br/>
                                    Куплены powerup-ы: <span style={valueStyle}>{this.props.session.purchasedPowerupsView}</span><br/>
                                    Использованы стартовые powerup-ы: <span style={valueStyle}>{this.props.session.startingPowerupsView}</span><br/>
                                    Куплены стартовые powerup-ы: <span style={valueStyle}>{this.props.session.purchasedStartingPowerupsView}</span><br/>
                                    Покупки за деньги: <span style={valueStyle}>{this.props.session.inAppPurchasesView}</span><br/>
                                    Потрачено монет: <span style={valueStyle}>{this.props.session.spentCoins}</span><br/>
                                    Заработано монет: <span style={valueStyle}>{this.props.session.earnedCoins}</span><br/>
                                    Осталось монет: <span style={valueStyle}>{this.props.session.remainingCoins}</span><br/>
                                    Осталось жизней: <span style={valueStyle}>{this.props.session.remainingLives}</span><br/>
                                    Пользователь: <span style={valueStyle}>{this.props.session.userId}</span><br/>
                                </p>
                            </div>
                        </FlexboxGridItem>
                    </FlexboxGrid>

                    <div style={{display: "flex", justifyContent: "center", paddingTop: 20}}>
                        <ButtonToolbar>
                            <Button onClick={() => {this.props.closePopover(); this.props.onViewLevel(this.props.session.level)}}>Статистика уровня</Button>
                            <Button onClick={() => {this.props.closePopover(); this.props.onViewLevelSessions(this.props.session.level)}}>Все сессии уровня</Button>
                            <Button onClick={() => {this.props.closePopover(); this.props.onViewUserSessions(this.props.session.userId)}}>Все сессии пользователя</Button>
                        </ButtonToolbar>
                    </div>
                </div>
            </Popover>
        );
    }
}

export default SessionPopover;