const Utils = {
    formatDuration(duration) {
        var hours   = Math.floor(duration / 3600);
        var minutes = Math.floor((duration - (hours * 3600)) / 60);
        var seconds = duration - (hours * 3600) - (minutes * 60);
        let result = "";

        if (seconds > 0) {
            result = seconds.toFixed(0) + " сек." + result;
        }

        if (minutes > 0) {
            result = minutes + " мин. " + result;
        }

        if (hours > 0) {
            result = hours + " ч. " + result;
        }

        return result;
    },

    dateRangeFromUrlParams(urlParams) {
        let fromDate = undefined;
        let toDate = undefined;

        if (!urlParams.has("alltime")) {
            
            fromDate = urlParams.has("from") ? new Date(parseInt(urlParams.get("from"))) : undefined;
            toDate = urlParams.has("to") ? new Date(parseInt(urlParams.get("to"))) : undefined;

            if (fromDate && !toDate) {
                toDate = new Date();
            }

            if (toDate && !fromDate) {
                fromDate = new Date(0);
            }

            if (!fromDate && !toDate) {
                fromDate = new Date();
                toDate = new Date();
            }

            if (fromDate) {
                fromDate = this.beginDayDate(fromDate);
            }
    
            if (toDate) {
                toDate = this.endDayDate(toDate);
            }
        } 

        return fromDate && toDate ? [fromDate, toDate] : [];
    },

    beginDayDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },

    endDayDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    }
}

export default Utils;