import React from "react";
import PropTypes from "prop-types";
import { Schema, Message, Panel, Form, FlexboxGrid, Button, ButtonToolbar } from "rsuite";
import Parse from "parse/dist/parse.min.js";

class Login extends React.Component {
    static get propTypes() { 
        return { 
            onLogin: PropTypes.func 
        }; 
    }

    constructor(props) {
        super(props)
        
        this.model = Schema.Model({
            username: Schema.Types.StringType().isRequired('Поле обязательно для заполнения.'),
            password: Schema.Types.StringType().isRequired('Поле обязательно для заполнения.')
        });
        this.form = React.createRef();
        this.state = {
            username: "",
            password: "",
            error: false,
            errorMessage: ""
        };

        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onLoginButton = this.onLoginButton.bind(this);
    }

    async onLoginButton() {
        if (this.form.current.check()) {
            try {
                let user = await Parse.User.logIn(this.state.username, this.state.password);  
                this.props.onLogin(user);  
            } catch (error) {
                this.setState({
                    error: true,
                    errorMessage: error.message
                });
            }
        }
    }

    onUsernameChanged(value) {
        this.setState({
            username: value
        });
    }

    onPasswordChanged(value) {
        this.setState({
            password: value
        });
    }

    render() {
        let error;
        if (this.state.error) {
            error = (
                <Message showIcon type="error" header="Ошибка" style={{marginTop: 20}}>
                {this.state.errorMessage}
                </Message>
            );
        }
        
        return (
            <FlexboxGrid justify="center" align="middle" style={{height: 600}}>
                <FlexboxGrid.Item style={{width: 350}}>
                    <Panel header={<h3>Вход</h3>} bordered>
                        <Form model={this.model} onSubmit={this.onLoginButton} checkTrigger="change" ref={this.form} fluid>
                            <Form.Group>
                                <Form.ControlLabel>Имя пользователя</Form.ControlLabel>
                                <Form.Control name="username" value={this.state.username} onChange={this.onUsernameChanged} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Пароль</Form.ControlLabel>
                                <Form.Control name="password" value={this.state.password} type="password" autoComplete="off" onChange={this.onPasswordChanged} />
                            </Form.Group>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" type="submit" >Войти</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                        {error}
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    }
}

export default Login;