import React from "react";
import PropTypes from "prop-types";
import { Container, Header, Footer, Navbar, Nav, Content, Dropdown} from "rsuite";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import Login from "./Login"
import Levels from "./Levels";
import Sessions from "./Sessions";
import Parse from "parse/dist/parse.min.js";

class App extends React.Component {
    static get propTypes() { 
        return {
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        
        this.state = {
            user: null
        }

        this.onLogin = this.onLogin.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
    }

    componentDidMount() {
        this.configureParse();

        this.setState({
            user: Parse.User.current(),
        });
    }

    configureParse() {
        Parse.initialize("club.smartgrow.beautysalon");
        //Parse.serverURL = "http://192.168.1.46:1337/parse";
        Parse.serverURL = "https://parse.smartgrow.club/parse";
    }

    onLogin(user) {
        this.setState({
            user: user
        });
    }

    onLogoutClick() {
        Parse.User.logOut();
        this.setState({
            user: null
        });
    }

    content() {
        if (this.state.user) {
            return (
                <Switch>
                    <Route path="/levels">
                        <Levels user={this.state.user}/>
                    </Route>
                    <Route path="/sessions">
                        <Sessions user={this.state.user}/>
                    </Route>
                    <Redirect from="/" to="/levels"/>
                </Switch>
            );
        } else {
            return (
                <Login onLogin={this.onLogin}/>
            );
        }
    }

    navigationItems() {
        if (this.state.user) {
            return (
                <div>
                    <Nav>
                        <Nav.Item active={this.props.location.pathname == "/levels"} href="/levels">
                            Статистика уровней
                        </Nav.Item>
                        <Nav.Item active={this.props.location.pathname == "/sessions"} href="/sessions">
                            Просмотр сессий
                        </Nav.Item>
                    </Nav>
                    <Nav pullRight>
                        <Dropdown placement="bottomEnd" title="Настройки">
                            <Dropdown.Item onClick={this.onLogoutClick}>Выход из аккаунта</Dropdown.Item>
                        </Dropdown>
                    </Nav>
                </div>
            );
        } else {
            return (
                <div/>
            );
        }
    }

    render() {
        return (
            <Container style={{height: "100vh"}}>
                <Header>
                    <Navbar appearance="inverse">
                        <Navbar.Brand>
                            <img src="/static/logo.svg" style={{width: "150px"}}/>
                        </Navbar.Brand>
                        {this.navigationItems()}
                    </Navbar>
                </Header>
                <Content>
                    {this.content()}
                </Content>
                <Footer></Footer>
            </Container>
        );   
    }
}

export default withRouter(App);
