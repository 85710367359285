import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Panel, Pagination, Whisper } from "rsuite";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import Parse from "parse/dist/parse.min.js";
import SessionsFilter from "./SessionsFilter";
import SessionPopover from "./SessionPopover";
import Utils from "./Utils";

class Sessions extends React.Component {
    static get propTypes() { 
        return {
            user: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            user: props.user,
            query: this.props.location.search,
            dateRange: Utils.dateRangeFromUrlParams(urlParams),
            userId: urlParams.has("user") ? urlParams.get("user"): undefined,
            level: urlParams.has("level") ? urlParams.get("level") : undefined,
            paginationLimit: urlParams.has("limit") ? parseInt(urlParams.get("limit")) : 50,
            paginationPage: urlParams.has("page") ? parseInt(urlParams.get("page")) : 1,
            paginationTotal: 0,
            sessions: [],
            loading: false,
        }

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onViewLevel = this.onViewLevel.bind(this);
        this.onViewLevelSessions = this.onViewLevelSessions.bind(this);
        this.onViewUserSessions = this.onViewUserSessions.bind(this);
    }

    componentDidMount() {
        this.refreshData(this.state.dateRange, this.state.level, this.state.userId, this.state.paginationLimit, this.state.paginationPage);
    }

    componentDidUpdate() {
        if (this.state.query != this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search);

            this.setState({
                query: this.props.location.search,
                dateRange: Utils.dateRangeFromUrlParams(urlParams),
                userId: urlParams.has("user") ? urlParams.get("user"): undefined,
                level: urlParams.has("level") ? urlParams.get("level") : undefined,
                paginationLimit: urlParams.has("limit") ? parseInt(urlParams.get("limit")) : 100,
                paginationPage: urlParams.has("page") ? parseInt(urlParams.get("page")) : 1
            }, () => {
                this.refreshData(this.state.dateRange, this.state.level, this.state.userId, this.state.paginationLimit, this.state.paginationPage);
            });
        }
    }

    refreshData(dateRange, level, userId, limit, page) {
        this.setState({
            loading: true
        }, async () => {
            let response = await this.fetchData(dateRange, level, userId, limit, page);
            
            this.setState({
                sessions: response.results,
                paginationTotal: response.count
            }, () => {
                this.setState({
                    loading: false
                });
            });
        });
    }

    async fetchData(dateRange, level, userId, limit, page) {
        let parameters = {};

        parameters.limit = limit;
        parameters.page = page;

        if (dateRange && dateRange.length == 2) {
            parameters.fromDate = dateRange[0];
            parameters.toDate = dateRange[1];
        }

        if (level) {
            parameters.level = level;
        }

        if (userId) {
            parameters.userId = userId;
        }

        let response = {
            results: [],
            count: 0
        };

        try {
            response = await Parse.Cloud.run("fetchMatch3Sessions", parameters);
        } catch (exception) {
            alert(exception);
        }

        response.results = response.results.map((value) => {
            let result = { 
                sessionId: value[0],
                userId: value[1],
                level: value[2],
                levelNumber: value[3],
                win: value[4],
                startTimestamp: value[5],
                finishTimestamp: value[6],
                duration: value[7],
                powerups: value[8],
                purchasedPowerups: value[9],
                startingPowerups: value[10],
                purchasedStartingPowerups: value[11],
                inAppPurchases: value[12],
                additionalMoves: value[13],
                remainingMoves: value[14],
                remainingLives: value[15],
                remainingCoins: value[16],
                remainingCrystals: value[17],
                spentCoins: value[18],
                earnedCoins: value[19],
                powerupPurchased: value[20],
                powerupUsed: value[21],
                startingPowerupUsed: value[22],
                startingPowerupPurchased: value[23],

                winView: "",
                durationView: "",
                finishTimestampView: "",
                powerupsView: "",
                purchasedPowerupsView: "",
                startingPowerupsView: "",
                purchasedStartingPowerupsView: "",
                inAppPurchasesView: ""
            }

            result.winView = result.win ? "Победа" : "Поражение";
            result.durationView = Utils.formatDuration(result.duration);
            result.finishTimestampView = new Date(Date.parse(result.finishTimestamp)).toLocaleString("ru-RU");
            result.inAppPurchasesView = result.inAppPurchases.map((value) => value.replace("club.smartgrow.beautysalon.product.", "")).reduce((prev, current) => (prev ? prev += ", " + current : current), "");

            let keyValue = 0;

            result.powerupsView = result.powerups.map((powerup) => 
                <img key={`${++keyValue}`} style={{width: "25px", height: "25px"}} src={`/static/${powerup}.png`}/>
            );

            result.purchasedPowerupsView = result.purchasedPowerups.map((powerup) => 
                <img key={`${++keyValue}`} style={{width: "25px", height: "25px"}} src={`/static/${powerup}.png`}/>
            );

            result.startingPowerupsView = result.startingPowerups.map((powerup) => 
                <img key={`${++keyValue}`} style={{width: "25px", height: "25px"}} src={`/static/${powerup}.png`}/>
            );

            result.purchasedStartingPowerupsView = result.purchasedStartingPowerups.map((powerup) => 
                <img key={`${++keyValue}`} style={{width: "25px", height: "25px"}} src={`/static/${powerup}.png`}/>
            );

            return result;
        });

        return response;
    }

    changeFilter(dateRange, level, userId, limit, page) {
        let url = new URL(this.props.location.pathname, "http://localhost");

        if (dateRange && dateRange.length == 2) {
            url.searchParams.append("from", dateRange[0].getTime());
            url.searchParams.append("to", dateRange[1].getTime());
        } else {
            url.searchParams.append("alltime", "true");
        }

        if (level) {
            url.searchParams.append("level", level);
        }

        if (userId) {
            url.searchParams.append("user", userId);
        }

        url.searchParams.append("limit", limit);
        url.searchParams.append("page", page);
        url.searchParams.append("t", new Date().getTime());

        this.props.history.replace(url.pathname + url.search);
    }

    onFilterChange(dateRange, level, userId) {
        this.changeFilter(dateRange, level, userId, this.state.paginationLimit, 1);
    }

    onChangeLimit(limit) {
        this.changeFilter(this.state.dateRange, this.state.level, this.state.userId, limit, 1);
    }

    onChangePage(page) {
        this.changeFilter(this.state.dateRange, this.state.level, this.state.userId, this.state.paginationLimit, page);
    }

    onViewLevel(level) {
        let url = new URL("/levels", "http://localhost");
        
        if (this.state.dateRange && this.state.dateRange.length == 2) {
            url.searchParams.append("from", this.state.dateRange[0].getTime());
            url.searchParams.append("to", this.state.dateRange[1].getTime());
        } else {
            url.searchParams.append("alltime", "true");
        }

        url.searchParams.append("fromLevel", level);
        url.searchParams.append("toLevel", level);
        this.props.history.push(url.pathname + url.search);
    }

    onViewLevelSessions(level) {
        this.changeFilter(this.state.dateRange, level, null, this.state.paginationLimit, 1);
    }

    onViewUserSessions(user) {
        this.changeFilter(this.state.dateRange, null, user, this.state.paginationLimit, 1);
    }

    pagination() {
        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <Pagination 
                    prev next first last boundaryLinks ellipsis
                    size="sm" 
                    maxButtons={10}
                    limit={this.state.paginationLimit} 
                    limitOptions={[50, 100, 300, 500]} 
                    activePage={this.state.paginationPage} 
                    total={this.state.paginationTotal} 
                    layout={['total', '-', 'limit', '|', 'pager']}
                    onChangeLimit={this.onChangeLimit}
                    onChangePage={this.onChangePage}>
                </Pagination>
            </div>
        );
    }

    render() {
        let tableLocalization = {
            emptyMessage: "Нет данных",
            loading: "Загрузка..."
        };

        const getPopover = (session) => {
            return ({ onClose, left, top, className }, ref) => {
                return (
                    <SessionPopover 
                        popoverStyle={{ left, top }} 
                        popoverClassName={className} 
                        popoverRef={ref} 
                        closePopover={onClose} 
                        onViewLevel={this.onViewLevel} 
                        onViewLevelSessions={this.onViewLevelSessions} 
                        onViewUserSessions={this.onViewUserSessions} 
                        session={session}
                    />
                );
            };
        }

        const SessionTableCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Whisper placement="auto" trigger="click" speaker={getPopover(rowData)}>
                    <Cell {...props}>
                    
                        <p>{rowData[dataKey]}</p>
                    </Cell>
                </Whisper>
            );
        };

        return (
            <div>
                <Panel>
                    <div style={{paddingBottom: 20}}>
                        <SessionsFilter key={this.state.query} dateRange={this.state.dateRange} level={this.state.level} userId={this.state.userId} onChange={this.onFilterChange}/>
                    </div>
                    {this.pagination()}
                    <Table data={this.state.sessions} sortColumn="finishTimestampView" sortType="desc" loading={this.state.loading} autoHeight affixHeader headerHeight={65} bordered cellBordered locale={tableLocalization}>
                        <Column width={190} align="right" sortable>
                            <HeaderCell>Дата</HeaderCell>
                            <SessionTableCell dataKey="finishTimestampView" />
                        </Column>
                        <Column width={80} align="center">
                            <HeaderCell>Номер<br/>уровня</HeaderCell>
                            <SessionTableCell dataKey="level" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Результат</HeaderCell>
                            <SessionTableCell dataKey="winView" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Длительность</HeaderCell>
                            <SessionTableCell dataKey="durationView" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Осталось ходов<br/>(или секунд)</HeaderCell>
                            <SessionTableCell dataKey="remainingMoves" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Докуплено ходов<br/>(или секунд)</HeaderCell>
                            <SessionTableCell dataKey="additionalMoves" />
                        </Column>
                        <Column flexGrow={1} align="center">
                            <HeaderCell>Использованы<br/>powerup-ы</HeaderCell>
                            <SessionTableCell dataKey="powerupsView" />
                        </Column>
                        <Column flexGrow={1} align="center">
                            <HeaderCell>Куплены<br/>powerup-ы</HeaderCell>
                            <SessionTableCell dataKey="purchasedPowerupsView" />
                        </Column>
                        <Column flexGrow={1} align="center">
                            <HeaderCell>Использованы<br/>стартовые powerup-ы</HeaderCell>
                            <SessionTableCell dataKey="startingPowerupsView" />
                        </Column>
                        <Column flexGrow={1} align="center">
                            <HeaderCell>Куплены<br/>стартовые powerup-ы</HeaderCell>
                            <SessionTableCell dataKey="purchasedStartingPowerupsView" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Покупки<br/>за деньги</HeaderCell>
                            <SessionTableCell dataKey="inAppPurchasesView" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Потрачено<br/>монет</HeaderCell>
                            <SessionTableCell dataKey="spentCoins" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Заработано<br/>монет</HeaderCell>
                            <SessionTableCell dataKey="earnedCoins" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Осталось<br/>монет</HeaderCell>
                            <SessionTableCell dataKey="remainingCoins" />
                        </Column>
                        <Column flexGrow={1} align="right">
                            <HeaderCell>Осталось<br/>жизней</HeaderCell>
                            <SessionTableCell dataKey="remainingLives" />
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Пользователь</HeaderCell>
                            <SessionTableCell dataKey="userId" />
                        </Column>
                    </Table>
                    {this.pagination()}
                </Panel>
            </div>
        );
    }
}

export default withRouter(Sessions);  