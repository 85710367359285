import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CustomProvider } from "rsuite";
import ruRU from "rsuite/locales/ru_RU";
import "./index.css";
import App from "./App";

ReactDOM.render(
    <React.StrictMode>
        <CustomProvider theme="light" locale={ruRU}>
            <Router>
                <App />
            </Router>
        </CustomProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

