import React from "react";
import PropTypes from "prop-types";
import { Button, Panel, FlexboxGrid, DateRangePicker, InputNumber, InputGroup, ButtonToolbar } from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import Utils from "./Utils";

class LevelsFilter extends React.Component {
    static get propTypes() { 
        return { 
            dateRange: PropTypes.array,
            fromLevel: PropTypes.string,
            toLevel: PropTypes.string,
            onChange: PropTypes.func 
        }; 
    }

    constructor(props) {
        super(props);

        let dateRange = [];
        if (props.dateRange && props.dateRange.length == 2) {
            dateRange.push(Utils.beginDayDate(props.dateRange[0]));
            dateRange.push(Utils.beginDayDate(props.dateRange[1]));
        }

        this.state = {
            dateRange: dateRange,
            fromLevel: props.fromLevel,
            toLevel: props.toLevel,
            canReset: false
        }

        this.state.canReset = this.canReset(this.state);

        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.onDatePickerChange = this.onDatePickerChange.bind(this);
        this.onFromLevelChange = this.onFromLevelChange.bind(this);
        this.onToLevelChange = this.onToLevelChange.bind(this);
    }

    canReset(state) {
        return (state.dateRange && state.dateRange.length == 2) || state.fromLevel || state.toLevel;
    }

    onUpdateClick() {
        let dateRange = [];
        if (this.state.dateRange && this.state.dateRange.length == 2) {
            dateRange.push(Utils.beginDayDate(this.state.dateRange[0]));
            dateRange.push(Utils.endDayDate(this.state.dateRange[1]));
        }

        this.props.onChange(dateRange, this.state.fromLevel, this.state.toLevel);
    }

    onResetClick() {
        this.setState({
            dateRange: [],
            fromLevel: undefined,
            toLevel: undefined
        }, () => {
            this.props.onChange(this.state.dateRange, this.state.fromLevel, this.state.toLevel);
        });
    }

    onDatePickerChange(value) {
        this.setState({
            dateRange: value
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    onFromLevelChange(level) {
        this.setState({
            fromLevel: level
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    onToLevelChange(level) {
        this.setState({
            toLevel: level
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    render() {
        return (
            <Panel>
                <FlexboxGrid>
                    <FlexboxGridItem>
                        <DateRangePicker isoWeek placeholder="Выберите период" value={this.state.dateRange} format="dd.MM.yyyy" disabledDate={DateRangePicker.afterToday()} onChange={this.onDatePickerChange}></DateRangePicker>
                    </FlexboxGridItem>
                    <FlexboxGridItem style={{paddingLeft: 10, width: 260}}>
                        <InputGroup>
                            <InputGroup.Addon>Уровни</InputGroup.Addon>
                            <InputNumber placeholder="с" value={this.state.fromLevel} onChange={this.onFromLevelChange}></InputNumber>
                            <InputNumber placeholder="по" value={this.state.toLevel} onChange={this.onToLevelChange}></InputNumber>
                        </InputGroup>
                    </FlexboxGridItem>
                    <FlexboxGridItem style={{paddingLeft: 10}}>
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={this.onUpdateClick}>Обновить</Button>
                            <Button disabled={!this.state.canReset} color="red" appearance="primary" onClick={this.onResetClick}>Сбросить</Button>
                        </ButtonToolbar>
                    </FlexboxGridItem>
                </FlexboxGrid>
            </Panel>
        );
    }
}

export default LevelsFilter;