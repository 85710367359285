import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Panel, Pagination, Whisper } from "rsuite";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import Parse from "parse/dist/parse.min.js";
import LevelsFilter from "./LevelsFilter";
import LevelPopover from "./LevelPopover";
import Utils from "./Utils";

class Levels extends React.Component {
    static get propTypes() { 
        return { 
            user: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
        }; 
    }

    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            user: props.user,
            query: this.props.location.search,
            dateRange: Utils.dateRangeFromUrlParams(urlParams),
            fromLevel: urlParams.has("fromLevel") ? urlParams.get("fromLevel") : undefined,
            toLevel: urlParams.has("toLevel") ? urlParams.get("toLevel") : undefined,
            sortedData: [],
            filteredData: [],
            sortColumn: "name",
            sortType: "asc",
            paginationLimit: 50,
            paginationPage: 1,
            paginationTotal: 0,
            loading: false,
        }

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSortColumn = this.onSortColumn.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onViewSessions = this.onViewSessions.bind(this);
    }

    componentDidMount() {
        this.refreshData(this.state.dateRange, this.state.fromLevel, this.state.toLevel);
    }

    componentDidUpdate() {
        if (this.state.query != this.props.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search);

            this.setState({
                query: this.props.location.search,
                dateRange: Utils.dateRangeFromUrlParams(urlParams),
                fromLevel: urlParams.has("fromLevel") ? urlParams.get("fromLevel") : undefined,
                toLevel: urlParams.has("toLevel") ? urlParams.get("toLevel") : undefined
            }, () => {
                this.refreshData(this.state.dateRange, this.state.fromLevel, this.state.toLevel);
            });
        }
    }

    sortData(data, sortColumn, sortType) {
        if (sortColumn == "name") {
            sortColumn = "number";
        }

        sortColumn = sortColumn.replace("View", "");

        const comparator = (value1, value2, type) => {
            if (type == "desc") {
                if (value1 > value2) {
                    return -1;
                } else if (value1 < value2) {
                    return 1;
                } 

                return 0;
            } else if (type == "asc") {
                if (value1 > value2) {
                    return 1;
                } else if (value1 < value2) {
                    return -1;
                }

                return 0;
            } 
            
            return 0;
        }

        return data.sort((first, second) => {
            let value1 = first[sortColumn];
            let value2 = second[sortColumn];

            let result = comparator(value1, value2, sortType);
            if (result == 0) {
                return comparator(first["number"], second["number"], "desc");
            }

            return result;
        });
    }

    filterData(data, page, limit) {
        return data.filter((value, index) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return index >= start && index < end;
        });
    }

    refreshData(dateRange, fromLevel, toLevel) {
        this.setState({
            loading: true
        }, async () => {
            let levels = await this.fetchData(dateRange, fromLevel, toLevel);

            this.setState((state) => {
                let sortedData = this.sortData(levels, state.sortColumn, state.sortType);
                let filteredData = this.filterData(sortedData, state.paginationPage, state.paginationLimit);
    
                return {
                    sortedData: sortedData,
                    filteredData: filteredData,
                    paginationTotal: sortedData.length,
                    paginationPage: 1
                };
            }, () => {
                this.setState({
                    loading: false
                });
            });

        });
    }

    async fetchData(dateRange, fromLevel, toLevel) {
        this.setState({
            loading: true
        });

        let parameters = {};
        if (dateRange && dateRange.length == 2) {
            parameters.fromDate = dateRange[0];
            parameters.toDate = dateRange[1];
            parameters.timeZoneOffset = dateRange[0].getTimezoneOffset();
        }

        if (fromLevel) {
            parameters.fromLevel = parseInt(fromLevel);
        }

        if (toLevel) {
            parameters.toLevel = parseInt(toLevel);
        }

        let data = [];
        try {
            data = await Parse.Cloud.run("fetchMatch3Analytics", parameters);
        } catch (exception) {
            alert(exception);
        }

        data = data.map((value) => {
            let result = { 
                name: value[0],
                number: value[1],
                winSessionCount: value[2],
                failedSessionCount: value[3],
                avgDurationOfWinSessions: value[4],
                avgRemainingMovesOfWinSessions: value[5],
                avgAdditionalMovesOfWinSessions: value[6],
                avgSessionCountForWin: value[7],
                maxRemainigMovesOfWinSessions: value[8],
                winSessionCountWithAdditionalMoves: value[9],
                winSessionCountWithPowerupUsed: value[10],
                winSessionCountWithPowerupPurchased: value[11],
                winSessionCountWithStartingPowerupUsed: value[12],
                winSessionCountWithStartingPowerupPurchased: value[13],
                winSessionCountWithInAppPurchaseUsed: value[14],
                failedSessionCountWithAdditionalMoves: value[15],
                failedSessionCountWithPowerupUsed: value[16],
                failedSessionCountWithPowerupPurchased: value[17],
                failedSessionCountWithStartingPowerupUsed: value[18],
                failedSessionCountWithStartingPowerupPurchased: value[19],
                failedSessionCountWithInAppPurchaseUsed: value[20],

                sessionCount: 0,
                winSessionCountPercent: 0,
                winSessionCountPercentView: "0",
                avgDurationOfWinSessionsView: "",
                avgRemainingMovesOfWinSessionsView: "",
                avgAdditionalMovesOfWinSessionsView: "",
                avgSessionCountForWinView: "",
                winSessionCountWithAdditionalMovesPercent: 0,
                winSessionCountWithAdditionalMovesPercentView: "",
                winSessionCountWithPowerupUsedPercent: "0",
                winSessionCountWithPowerupUsedPercentView: "",
                winSessionCountWithPowerupPurchasedPercent: 0,
                winSessionCountWithPowerupPurchasedPercentView: "",
                winSessionCountWithStartingPowerupUsedPercent: 0,
                winSessionCountWithStartingPowerupUsedPercentView: "",
                winSessionCountWithStartingPowerupPurchasedPercent: 0,
                winSessionCountWithStartingPowerupPurchasedPercentView: "",
                winSessionCountWithInAppPurchaseUsedPercent: 0,
                winSessionCountWithInAppPurchaseUsedPercentView: ""
            }

            result.sessionCount = result.winSessionCount + result.failedSessionCount;
            result.winSessionCountPercent = result.winSessionCount / result.sessionCount;
            result.winSessionCountPercentView = `${(result.winSessionCountPercent * 100).toFixed(0)}% (${result.winSessionCount} из ${result.sessionCount})`;

            result.avgDurationOfWinSessionsView = Utils.formatDuration(result.avgDurationOfWinSessions);
            result.avgRemainingMovesOfWinSessionsView = result.avgRemainingMovesOfWinSessions.toFixed(1).replace(".0", "");
            result.avgAdditionalMovesOfWinSessionsView = result.avgAdditionalMovesOfWinSessions.toFixed(1).replace(".0", "");
            result.avgSessionCountForWinView = result.avgSessionCountForWin.toFixed(1).replace(".0", "");
            
            if (result.winSessionCountWithAdditionalMoves > 0) {
                result.winSessionCountWithAdditionalMovesPercent = result.winSessionCountWithAdditionalMoves / result.winSessionCount;
            }

            result.winSessionCountWithAdditionalMovesPercentView = `${(result.winSessionCountWithAdditionalMovesPercent * 100).toFixed(0)}% (${result.winSessionCountWithAdditionalMoves} из ${result.winSessionCount})`;

            if (result.winSessionCountWithPowerupUsed > 0) {
                result.winSessionCountWithPowerupUsedPercent = result.winSessionCountWithPowerupUsed / result.winSessionCount;
            }

            result.winSessionCountWithPowerupUsedPercentView = `${(result.winSessionCountWithPowerupUsedPercent * 100).toFixed(0)}% (${result.winSessionCountWithPowerupUsed} из ${result.winSessionCount})`;

            if (result.winSessionCountWithPowerupPurchased > 0) {
                result.winSessionCountWithPowerupPurchasedPercent = result.winSessionCountWithPowerupPurchased / result.winSessionCount;
            }

            result.winSessionCountWithPowerupPurchasedPercentView = `${(result.winSessionCountWithPowerupPurchasedPercent * 100).toFixed(0)}% (${result.winSessionCountWithPowerupPurchased} из ${result.winSessionCount})`;

            if (result.winSessionCountWithStartingPowerupUsed > 0) {
                result.winSessionCountWithStartingPowerupUsedPercent = result.winSessionCountWithStartingPowerupUsed / result.winSessionCount;
            }

            result.winSessionCountWithStartingPowerupUsedPercentView = `${(result.winSessionCountWithStartingPowerupUsedPercent * 100).toFixed(0)}% (${result.winSessionCountWithStartingPowerupUsed} из ${result.winSessionCount})`;

            if (result.winSessionCountWithStartingPowerupPurchased > 0) {
                result.winSessionCountWithStartingPowerupPurchasedPercent = result.winSessionCountWithStartingPowerupPurchased / result.winSessionCount;
            }

            result.winSessionCountWithStartingPowerupPurchasedPercentView = `${(result.winSessionCountWithStartingPowerupPurchasedPercent * 100).toFixed(0)}% (${result.winSessionCountWithStartingPowerupPurchased} из ${result.winSessionCount})`;

            if (result.winSessionCountWithInAppPurchaseUsed > 0) {
                result.winSessionCountWithInAppPurchaseUsedPercent = result.winSessionCountWithInAppPurchaseUsed / result.winSessionCount;
            }

            result.winSessionCountWithInAppPurchaseUsedPercentView = `${(result.winSessionCountWithInAppPurchaseUsedPercent * 100).toFixed(0)}% (${result.winSessionCountWithInAppPurchaseUsed} из ${result.winSessionCount})`;

            return result;
        });

        return data;
    }

    changeFilter(dateRange, fromLevel, toLevel) {
        let url = new URL(this.props.location.pathname, "http://localhost");

        if (dateRange && dateRange.length == 2) {
            url.searchParams.append("from", dateRange[0].getTime());
            url.searchParams.append("to", dateRange[1].getTime());
        } else {
            url.searchParams.append("alltime", "true");
        }

        if (fromLevel) {
            url.searchParams.append("fromLevel", fromLevel);
        }

        if (toLevel) {
            url.searchParams.append("toLevel", toLevel);
        }

        url.searchParams.append("t", new Date().getTime());
        this.props.history.replace(url.pathname + url.search);
    }

    onFilterChange(dateRange, fromLevel, toLevel) {
        this.changeFilter(dateRange, fromLevel, toLevel);
    }

    onSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState((state) => {
                let sortedData = this.sortData(state.sortedData, sortColumn, sortType);
                let filteredData = this.filterData(sortedData, 1, state.paginationLimit);

                return {
                    sortColumn: sortColumn,
                    sortType: sortType,
                    sortedData: sortedData,
                    filteredData: filteredData,
                    paginationTotal: sortedData.length,
                    paginationPage: 1
                };
            }, () => {
                this.setState({
                    loading: false
                });
            });
        }, 100);
    }

    onChangeLimit(limit) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState((state) => ({
                filteredData: this.filterData(state.sortedData, 1, limit),
                paginationPage: 1,
                paginationLimit: limit
            }), () => {
                this.setState({
                    loading: false
                });
            });
        }, 100);
    }

    onChangePage(page) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState((state) => ({
                filteredData: this.filterData(state.sortedData, page, state.paginationLimit),
                paginationPage: page,
                loading: false
            }));
        }, 100);
    }

    onViewSessions(level) {
        let url = new URL("/sessions", "http://localhost");
        
        if (this.state.dateRange && this.state.dateRange.length == 2) {
            url.searchParams.append("from", this.state.dateRange[0].getTime());
            url.searchParams.append("to", this.state.dateRange[1].getTime());
        } else {
            url.searchParams.append("alltime", "true");
        }

        url.searchParams.append("level", level);
        this.props.history.push(url.pathname + url.search);
    }

    pagination() {
        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <Pagination 
                    prev next first last boundaryLinks ellipsis
                    size="sm" 
                    maxButtons={10}
                    limit={this.state.paginationLimit} 
                    limitOptions={[50, 100, 300, 500]} 
                    activePage={this.state.paginationPage} 
                    total={this.state.paginationTotal} 
                    layout={['total', '-', 'limit', '|', 'pager']}
                    onChangeLimit={this.onChangeLimit}
                    onChangePage={this.onChangePage}>
                </Pagination>
            </div>
        );
    }

    render() {
        const tableLocalization = {
            emptyMessage: "Нет данных",
            loading: "Загрузка..."
        };

        const getPopover = (level) => {
            return ({ onClose, left, top, className }, ref) => {
                return (
                    <LevelPopover 
                        popoverStyle={{ left, top }} 
                        popoverClassName={className} 
                        popoverRef={ref} 
                        closePopover={onClose} 
                        onViewSessions={this.onViewSessions} 
                        level={level}
                    />
                );
            };
        }

        const LevelTableCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Whisper placement="auto" trigger="click" speaker={getPopover(rowData)}>
                    <Cell {...props}>
                        <p>{rowData[dataKey]}</p>
                    </Cell>
                </Whisper>
            );
        };

        return (
            <div>
                <LevelsFilter key={this.state.query} dateRange={this.state.dateRange} fromLevel={this.state.fromLevel} toLevel={this.state.toLevel} onChange={this.onFilterChange}/>
                <Panel>
                    {this.pagination()}
                    <Table data={this.state.filteredData} sortColumn={this.state.sortColumn} sortType={this.state.sortType} loading={this.state.loading} autoHeight affixHeader headerHeight={85} bordered cellBordered onSortColumn={this.onSortColumn} locale={tableLocalization}>
                        <Column width={85} align="center" sortable>
                            <HeaderCell>Номер<br/>уровня</HeaderCell>
                            <LevelTableCell dataKey="name"/>
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля<br/>побед</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountPercentView" />
                        </Column>
                        <Column flexGrow={1} align="right" sortable>
                            <HeaderCell>Среднее<br/>количество попыток<br/>для победы</HeaderCell>
                            <LevelTableCell dataKey="avgSessionCountForWinView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Средняя<br/>продолжительность<br/>победы</HeaderCell>
                            <LevelTableCell dataKey="avgDurationOfWinSessionsView" />
                        </Column>
                        <Column flexGrow={1} align="right" sortable>
                            <HeaderCell>Среднее количество<br/>ост. ходов после победы<br/>(или секунд)</HeaderCell>
                            <LevelTableCell dataKey="avgRemainingMovesOfWinSessionsView" />
                        </Column>
                        <Column flexGrow={1} align="right" sortable>
                            <HeaderCell>Макс. количество<br/>ост. ходов после победы<br/>(или секунд)</HeaderCell>
                            <LevelTableCell dataKey="maxRemainigMovesOfWinSessions" />
                        </Column>
                        <Column flexGrow={1} align="right" sortable>
                            <HeaderCell>Среднее количество<br/>доп. ходов для победы<br/>(или секунд)</HeaderCell>
                            <LevelTableCell dataKey="avgAdditionalMovesOfWinSessionsView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с доп. ходами</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithAdditionalMovesPercentView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с использованием<br/>бустов</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithPowerupUsedPercentView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с покупкой<br/>бустов</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithPowerupPurchasedPercentView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с использованием<br/>стартовых бустов</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithStartingPowerupUsedPercentView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с покупкой стартовых<br/>бустов</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithStartingPowerupPurchasedPercentView" />
                        </Column>
                        <Column flexGrow={1.2} align="right" sortable>
                            <HeaderCell>Доля побед<br/>с покупками<br/>за деньги</HeaderCell>
                            <LevelTableCell dataKey="winSessionCountWithInAppPurchaseUsedPercentView" />
                        </Column>
                    </Table>
                    {this.pagination()}
                </Panel>
            </div>
        );
    }
}

export default withRouter(Levels);  