import React from "react";
import PropTypes from "prop-types";
import { Button, FlexboxGrid, DateRangePicker, Input, InputNumber, InputGroup, ButtonToolbar } from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import Utils from "./Utils";

class SessionsFilter extends React.Component {
    static get propTypes() { 
        return { 
            dateRange: PropTypes.array,
            level: PropTypes.string,
            userId: PropTypes.string,
            onChange: PropTypes.func 
        }; 
    }

    constructor(props) {
        super(props);

        let dateRange = [];
        if (props.dateRange && props.dateRange.length == 2) {
            dateRange.push(Utils.beginDayDate(props.dateRange[0]));
            dateRange.push(Utils.beginDayDate(props.dateRange[1]));
        }

        this.state = {
            dateRange: dateRange,
            level: props.level,
            userId: props.userId,
            canReset: false
        }

        this.state.canReset = this.canReset(this.state);

        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.onDatePickerChange = this.onDatePickerChange.bind(this);
        this.onLevelChange = this.onLevelChange.bind(this);
        this.onUserIdChange = this.onUserIdChange.bind(this);
    }

    canReset(state) {
        return (state.dateRange && state.dateRange.length == 2) || state.level || state.userId;
    }

    onUpdateClick() {
        let dateRange = [];
        if (this.state.dateRange && this.state.dateRange.length == 2) {
            dateRange.push(Utils.beginDayDate(this.state.dateRange[0]));
            dateRange.push(Utils.endDayDate(this.state.dateRange[1]));
        }

        this.props.onChange(dateRange, this.state.level, this.state.userId);
    }

    onResetClick() {
        this.setState({
            dateRange: [],
            level: undefined,
            userId: undefined
        }, () => {
            this.props.onChange(this.state.dateRange, this.state.level, this.state.userId);
        });
    }

    onDatePickerChange(value) {
        this.setState({
            dateRange: value
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    onLevelChange(level) {
        this.setState({
            level: level
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    onUserIdChange(userId) {
        this.setState({
            userId: userId
        }, () => {
            this.setState((state) => ({
                canReset: this.canReset(state)
            }));
        });
    }

    render() {
        return (
            <FlexboxGrid>
                <FlexboxGridItem>
                    <DateRangePicker isoWeek placeholder="Выберите период" value={this.state.dateRange} format="dd.MM.yyyy" disabledDate={DateRangePicker.afterToday()} onChange={this.onDatePickerChange}></DateRangePicker>
                </FlexboxGridItem>
                <FlexboxGridItem style={{paddingLeft: 10, width: 200}}>
                    <InputGroup>
                        <InputGroup.Addon>Уровень</InputGroup.Addon>
                        <InputNumber placeholder="номер" value={this.state.level} onChange={this.onLevelChange}></InputNumber>
                    </InputGroup>
                </FlexboxGridItem>
                <FlexboxGridItem style={{paddingLeft: 10, width: 300}}>
                    <InputGroup>
                        <InputGroup.Addon>Пользователь</InputGroup.Addon>
                        <Input placeholder="идентификатор" value={this.state.userId} onChange={this.onUserIdChange}></Input>
                    </InputGroup>
                </FlexboxGridItem>
                <FlexboxGridItem style={{paddingLeft: 10}}>
                    <ButtonToolbar>
                        <Button appearance="primary"onClick={this.onUpdateClick}>Обновить</Button>
                        <Button disabled={!this.state.canReset} color="red" appearance="primary"onClick={this.onResetClick}>Сбросить</Button>
                    </ButtonToolbar>
                </FlexboxGridItem>
            </FlexboxGrid>
        );
    }
}

export default SessionsFilter;