import React from "react";
import PropTypes from "prop-types";
import { IconButton, Button, Popover, Carousel, FlexboxGrid } from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import CloseIcon from "@rsuite/icons/Close";

class LevelPopover extends React.Component {
    static get propTypes() { 
        return { 
            level: PropTypes.object.isRequired,
            popoverStyle: PropTypes.object.isRequired,
            popoverClassName: PropTypes.string.isRequired,
            popoverRef: PropTypes.func.isRequired,
            closePopover: PropTypes.func.isRequired,
            onViewSessions: PropTypes.func
        }; 
    }

    constructor(props) {
        super(props);

        this.state = {
            screenshotUrls: []
        };
    }

    componentDidMount() {
        this.loadNextScreenshot(2);
    }

    loadNextScreenshot(index) {
        let url = `/static/levels/Level${this.props.level.name}-${index}.jpg`;

        let http = new XMLHttpRequest();
        http.onload = () => {
            if (http.status == 200) {
                if (http.getResponseHeader("content-type") == "image/jpeg") {
                    this.setState((state) => ({
                        screenshotUrls: state.screenshotUrls.concat([url])
                    }));
    
                    if (index < 6) {
                        this.loadNextScreenshot(index + 1);
                    }
                }
            }
        }

        http.open('HEAD', url, true);
        http.send();
    }

    render() {
        const valueStyle = {
            fontWeight: "bold"
        };

        return (
            <Popover style={this.props.popoverStyle} className={this.props.popoverClassName} ref={this.props.popoverRef}>
                <div style={{ position: "absolute", top: 0, right: 0, paddingRight: 10, paddingTop: 10}}>
                    <IconButton icon={<CloseIcon/>} onClick={this.props.closePopover}></IconButton>
                </div>

                <FlexboxGrid>
                    <FlexboxGridItem>
                        <Carousel placement="bottom" shape="dot" style={{width: "400px", height: "300px"}}>
                            <img src={`/static/levels/Level${this.props.level.name}.jpg`}/>
                            {this.state.screenshotUrls.map((value) => 
                                <img key={value} src={value}/>
                            )}
                        </Carousel>
                    </FlexboxGridItem>
                    <FlexboxGridItem>
                        <div style={{paddingLeft: 10}}>
                            <h5>Уровень: {this.props.level.name}</h5>
                            <p style={{paddingTop: 10}}>
                                Доля побед: <span style={valueStyle}>{this.props.level.winSessionCountPercentView}</span><br/>
                                Среднее количество попыток для победы: <span style={valueStyle}>{this.props.level.avgSessionCountForWinView}</span><br/>
                                Средняя продолжительность: <span style={valueStyle}>{this.props.level.avgDurationOfWinSessionsView}</span><br/>
                                Среднее количество ост. ходов после победы: <span style={valueStyle}>{this.props.level.avgRemainingMovesOfWinSessionsView}</span><br/>
                                Макс. количество ост. ходов после победы: <span style={valueStyle}>{this.props.level.maxRemainigMovesOfWinSessions}</span><br/>
                                Среднее количество доп. ходов для победы: <span style={valueStyle}>{this.props.level.avgAdditionalMovesOfWinSessionsView}</span><br/>
                                Доля побед с доп. ходами: <span style={valueStyle}>{this.props.level.winSessionCountWithAdditionalMovesPercentView}</span><br/>
                                Доля побед с использованием бустов: <span style={valueStyle}>{this.props.level.winSessionCountWithPowerupUsedPercentView}</span><br/>
                                Доля побед с покупкой бустов: <span style={valueStyle}>{this.props.level.winSessionCountWithPowerupPurchasedPercentView}</span><br/>
                                Доля побед с использованием стартовых бустов: <span style={valueStyle}>{this.props.level.winSessionCountWithStartingPowerupUsedPercentView}</span><br/>
                                Доля побед с покупкой стартовых бустов: <span style={valueStyle}>{this.props.level.winSessionCountWithStartingPowerupPurchasedPercentView}</span><br/>
                                Доля побед с покупками за деньги: <span style={valueStyle}>{this.props.level.winSessionCountWithInAppPurchaseUsedPercentView}</span><br/>
                            </p>
                        </div>
                    </FlexboxGridItem>
                </FlexboxGrid>

                <div style={{display: "flex", justifyContent: "center", paddingTop: 10}}>
                    <Button onClick={() => {this.props.closePopover(); this.props.onViewSessions(this.props.level.name)}}>Смотреть сессии</Button>
                </div>
            </Popover>
        );
    }
}

export default LevelPopover;